@import "./colors.scss";

.mat-expansion-panel-body {
  padding: 0 !important;
}
.mat-expansion-panel {
  background-color: $accordion !important;
  box-sizing: border-box !important;
}

.accordion-content {
  padding: 1rem;
  display: flex;
  border-bottom: 0.1rem solid $accordion !important;
}

.mat-expansion-panel-header {
  padding: 0.5rem 1rem !important;
}

.mat-content {
  width: 100% !important;
}

.mat-expansion-indicator {
  padding: 0 0.25rem !important;
}
