dialog {
  position: relative;
  border: none !important;
  border-radius: 5px;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  padding: 1.6rem;
  max-width: 100%;
  max-height: 90%;
  overflow-y: scroll;
}

// dialog[open]::after {
//   content: "";
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black */
//   z-index: 500; /* Below the dialog */
//   display: none;
// }

// dialog::backdrop {
//   background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black */
// }

// dialog[open]::after {
//   display: block;
// }

dialog[open] {
  animation: fadein 300ms ease-in forwards;
  z-index: 501;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
