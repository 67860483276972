.non-error-snack {
  position: fixed !important;
  // max-width: 40vw !important;
  height: auto !important;
  top: 90% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  // background-color: #2e7547;
}

.non-error-snack > .mdc-snackbar__container > .mdc-snackbar__surface {
  background-color: $green !important;
  border-color: $green !important;
}

.mdc-snackbar--open > .mdc-snackbar__surface {
  background-color: $green !important;
}

mat-snack-bar-container {
  background-color: $green !important;
}

.mdc-snackbar__label {
  color: white !important;
  background-color: $green !important;
  display: flex !important;
  align-items: center !important;
}

.mdc-snackbar__actions {
  color: white;
  //   background-color: darken($green, 10%) !important;
  // border: 1px solid white !important;
  //   margin: 1rem !important;
  //   border-radius: 3px solid !important;
}

.mat-mdc-snack-bar-container
  .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
  color: $white !important;
  --mat-text-button-state-layer-color: $green !important;
  --mat-text-button-ripple-color: $green !important;
}

// .mat-button.mat-primary .mat-button-focus-overlay {
//     background-color: purple !important;
// }

// .mat-mdc-focus-indicator {
//     filter: brightness(75%) !important;
// }

// .mdc-snackbar__actions:hover {
//     filter: brightness(75%) !important;
// }
