#other-menu-container {
  color: white;
}

#other-menu-container * {
  box-sizing: border-box;
}

#other-menu-container.slideIn {
  visibility: visible;
  animation-name: slidemenuin;
  animation-duration: 1.5s;
  animation-timing-function: ease-in;
  animation-delay: 0s;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-play-state: running;
}

#other-menu-container.slideOut {
  -webkit-animation: slidemenuout 1.5s ease-in 0 normal;
  /*-webkit-animation-delay: 0s;*/
  animation: slidemenuout 1.5s ease-in 0 normal;
  /*animation-delay: 20s;*/
  /*animation-name: slidemenuout;
      animation-duration: 1.5s;
      animation-timing-function: ease-out;
      animation-delay: 0s;
      animation-direction: normal;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
      animation-play-state: running;*/
}

@keyframes slidemenuin {
  100% {
    left: 0%;
  }
}

@-webkit-keyframes slidemenuin {
  100% {
    left: 0%;
  }
}

@keyframes slidemenuout {
  100% {
    left: 100%;
  }
}

@-webkit-keyframes slidemenuout {
  100% {
    left: 100%;
  }
}

#main-menu .othermenu {
  background: #004;
}

#main-menu.otherMenu .othermenu {
  background: $otherMenu;
}

.othermenu {
  position: relative;
  list-style: none;
  /*#000044;*/
  display: inline-block;
  padding: 0;
  margin-bottom: 0;
}

.othermenu li {
  display: inline-block;
  position: relative;
  padding: 0.3rem 0.3rem;
  cursor: pointer;
  z-index: 5;
  background-color: inherit;
}

.othermenu li.user_av {
  color: #e9e9e9;
  font-weight: bold;
}

.othermenu li.user_av a {
  padding: 0.3rem 1rem;
}

.othermenu li.user_av img {
  display: inline-block;
  margin-right: 0.25rem;
}

.othermenu li.user_av span {
  color: #fff !important;
  vertical-align: middle;
  padding: 0;
}

.othermenu li span {
  margin: 0.3rem 0.3rem;
  vertical-align: middle;
  padding: 0 1rem 0 0;
}

#other-menu-container li {
  margin: 0;
}

.other-menu-page {
  background-color: $otherMenu !important;
  transition: all 0.4s ease-in;
}

.other-menu-page:hover {
  background-color: $primary !important;
  transition: all 0.2s ease-in;
}

.drop {
  overflow: hidden;
  list-style: none;
  position: absolute;
  padding: 0;
  /*width: 100%;*/
  left: 0;
  top: 2.3rem;
  margin: 0;
  background-color: inherit !important;
}

.drop div {
  -webkit-transform: translate(0, -100%);
  -moz-transform: translate(0, -100%);
  -ms-transform: translate(0, -100%);
  transform: translate(0, -100%);
  -webkit-transition: all 0.5s 1s;
  -moz-transition: all 0.5s 0.1s;
  -ms-transition: all 0.5s 0.1s;
  transition: all 0.5s 1s;
  position: relative;
  border: solid 1px #fff;
  display: none;
  padding: 0.3rem 0;
}

.drop li {
  display: block;
  padding: 0;
  width: 100%;
  padding: 0 1rem 0.3rem 1rem !important;
}

.drop li a {
  color: #fff;
  white-space: nowrap;
  background-color: inherit;
}

#marker {
  height: 6px;
  background: #7f7fa1 !important;
  position: absolute;
  bottom: 0;
  width: 10rem;
  z-index: 2;
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  -ms-transition: all 0.35s;
  transition: all 0.35s;
}

.othermenu li:nth-child(1):hover ul div {
  display: inherit;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}

.othermenu li:nth-child(1):hover ~ #marker {
  display: inherit;
  -webkit-transform: translate(0rem, 0);
  -moz-transform: translate(0rem, 0);
  -ms-transform: translate(0rem, 0);
  transform: translate(0rem, 0);
}

.othermenu li:nth-child(2):hover ul div {
  display: inherit;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}

.othermenu li:nth-child(2):hover ~ #marker {
  display: inherit;
  -webkit-transform: translate(10rem, 0);
  -moz-transform: translate(10rem, 0);
  -ms-transform: translate(10rem, 0);
  transform: translate(10rem, 0);
}

.othermenu li:nth-child(3):hover ul div {
  display: inherit;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}

.othermenu li:nth-child(3):hover ~ #marker {
  display: inherit;
  -webkit-transform: translate(20rem, 0);
  -moz-transform: translate(20rem, 0);
  -ms-transform: translate(20rem, 0);
  transform: translate(20rem, 0);
}

.othermenu li:nth-child(4):hover ul div {
  display: inherit;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}

.othermenu li:nth-child(4):hover ~ #marker {
  display: inherit;
  -webkit-transform: translate(30rem, 0);
  -moz-transform: translate(30rem, 0);
  -ms-transform: translate(30rem, 0);
  transform: translate(30rem, 0);
}

.othermenu li:nth-child(5):hover ul div {
  display: inherit;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}

.othermenu li:nth-child(5):hover ~ #marker {
  display: inherit;
  -webkit-transform: translate(40rem, 0);
  -moz-transform: translate(40rem, 0);
  -ms-transform: translate(40rem, 0);
  transform: translate(40rem, 0);
}

.othermenu li:nth-child(6):hover ul div {
  display: inherit;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}

.othermenu li:nth-child(6):hover ~ #marker {
  display: inherit;
  -webkit-transform: translate(50rem, 0);
  -moz-transform: translate(50rem, 0);
  -ms-transform: translate(50rem, 0);
  transform: translate(50rem, 0);
}

.othermenu li:nth-child(7):hover ul div {
  display: inherit;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}

.othermenu li:nth-child(7):hover ~ #marker {
  display: inherit;
  -webkit-transform: translate(60rem, 0);
  -moz-transform: translate(60rem, 0);
  -ms-transform: translate(60rem, 0);
  transform: translate(60rem, 0);
}

.othermenu li:nth-child(8):hover ul div {
  display: inherit;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}

.othermenu li:nth-child(8):hover ~ #marker {
  display: inherit;
  -webkit-transform: translate(70rem, 0);
  -moz-transform: translate(70rem, 0);
  -ms-transform: translate(70rem, 0);
  transform: translate(70rem, 0);
}

.othermenu li:nth-child(9):hover ul div {
  display: inherit;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}

.othermenu li:nth-child(9):hover ~ #marker {
  display: inherit;
  -webkit-transform: translate(80rem, 0);
  -moz-transform: translate(80rem, 0);
  -ms-transform: translate(80rem, 0);
  transform: translate(80rem, 0);
}

.othermenu li:nth-child(10):hover ul div {
  display: inherit;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}

.othermenu li:nth-child(10):hover ~ #marker {
  display: inherit;
  -webkit-transform: translate(90rem, 0);
  -moz-transform: translate(90rem, 0);
  -ms-transform: translate(90rem, 0);
  transform: translate(90rem, 0);
}

.othermenu li:nth-child(11):hover ul div {
  display: inherit;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}

.othermenu li:nth-child(11):hover ~ #marker {
  display: inherit;
  -webkit-transform: translate(100rem, 0);
  -moz-transform: translate(100rem, 0);
  -ms-transform: translate(100rem, 0);
  transform: translate(100rem, 0);
}

.othermenu li:nth-child(12):hover ul div {
  display: inherit;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}

.othermenu li:nth-child(12):hover ~ #marker {
  display: inherit;
  -webkit-transform: translate(110rem, 0);
  -moz-transform: translate(110rem, 0);
  -ms-transform: translate(110rem, 0);
  transform: translate(110rem, 0);
}

.othermenu li:nth-child(13):hover ul div {
  display: inherit;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}

.othermenu li:nth-child(13):hover ~ #marker {
  display: inherit;
  -webkit-transform: translate(120rem, 0);
  -moz-transform: translate(120rem, 0);
  -ms-transform: translate(120rem, 0);
  transform: translate(120rem, 0);
}

.othermenu li:nth-child(14):hover ul div {
  display: inherit;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}

.othermenu li:nth-child(14):hover ~ #marker {
  display: inherit;
  -webkit-transform: translate(130rem, 0);
  -moz-transform: translate(130rem, 0);
  -ms-transform: translate(130rem, 0);
  transform: translate(130rem, 0);
}

.othermenu li:nth-child(15):hover ul div {
  display: inherit;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}

.othermenu li:nth-child(15):hover ~ #marker {
  display: inherit;
  -webkit-transform: translate(140rem, 0);
  -moz-transform: translate(140rem, 0);
  -ms-transform: translate(140rem, 0);
  transform: translate(140rem, 0);
}

.othermenu li:nth-child(16):hover ul div {
  display: inherit;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}

.othermenu li:nth-child(16):hover ~ #marker {
  display: inherit;
  -webkit-transform: translate(150rem, 0);
  -moz-transform: translate(150rem, 0);
  -ms-transform: translate(150rem, 0);
  transform: translate(150rem, 0);
}

.othermenu li:nth-child(17):hover ul div {
  display: inherit;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}

.othermenu li:nth-child(17):hover ~ #marker {
  display: inherit;
  -webkit-transform: translate(160rem, 0);
  -moz-transform: translate(160rem, 0);
  -ms-transform: translate(160rem, 0);
  transform: translate(160rem, 0);
}

.othermenu li:nth-child(18):hover ul div {
  display: inherit;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}

.othermenu li:nth-child(18):hover ~ #marker {
  display: inherit;
  -webkit-transform: translate(170rem, 0);
  -moz-transform: translate(170rem, 0);
  -ms-transform: translate(170rem, 0);
  transform: translate(170rem, 0);
}

.othermenu li:nth-child(19):hover ul div {
  display: inherit;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}

.othermenu li:nth-child(19):hover ~ #marker {
  display: inherit;
  -webkit-transform: translate(180rem, 0);
  -moz-transform: translate(180rem, 0);
  -ms-transform: translate(180rem, 0);
  transform: translate(180rem, 0);
}

.othermenu li:nth-child(20):hover ul div {
  display: inherit;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}

.othermenu li:nth-child(20):hover ~ #marker {
  display: inherit;
  -webkit-transform: translate(190rem, 0);
  -moz-transform: translate(190rem, 0);
  -ms-transform: translate(190rem, 0);
  transform: translate(190rem, 0);
}
