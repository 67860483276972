.button {
  // display: inline-block;
  -webkit-appearance: none;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  border: 1px solid transparent;
  border-radius: 5px;
  padding: 0.75em 1em;
  margin: 0 0 1rem;
  font-size: 0.9rem;
  background-color: $primary;
  color: $white;
}

.button.tiny {
  font-size: 0.6rem;
}

.buttonSmaller {
  height: 2.3rem;
  width: 2.3rem;
}

button,
input[type="button"] {
  cursor: pointer !important;
}

.button:not([class*="ImgBackground"]):hover:enabled,
input[type="button"]:not([class*="ImgBackground"]):hover:enabled {
  // filter: brightness(75%);
  background-color: $accent-primary !important;
}

.button.alert:hover:enabled,
input[type="button"].alert:hover:enabled {
  // filter: brightness(75%);
  background-color: $alert !important;
}

.button.success:hover:enabled,
input[type="button"].success:hover:enabled {
  // filter: brightness(75%);
  background-color: $dark-green !important;
}

button:disabled,
button[disabled] {
  cursor: not-allowed !important;
  filter: opacity(30%);
}

.button.secondary {
  background-color: $secondary;
  color: #000;
}
.button.secondary:hover {
  background-color: $light-grey !important;
  color: #000 !important;
}
