::-webkit-scrollbar {
  width: 0.6rem;
  height: 0.3rem;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 25px;
  background: $secondary;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 25px;
  // border: 1px solid $primary;
  background: $primary;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  filter: opacity(10%);
  cursor: grab;
}

/* Handle on focus */
::-webkit-scrollbar-thumb:active {
  cursor: grabbing;
}
