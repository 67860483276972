@import "./colors.scss";
@import "./animate.scss";

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

.hide {
  display: none;
}

[hidden],
[hidden="true"],
template {
  display: none;
}

a:active,
a:hover {
  outline: 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b,
optgroup,
strong {
  font-weight: 700;
}

dfn {
  font-style: italic;
}

mark {
  background: #ff0;
  color: $black;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

.button,
img {
  vertical-align: middle;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

.orbit-caption,
.sticky.is-anchored.is-at-bottom,
.sticky.is-stuck.is-at-bottom {
  bottom: 0;
}

img {
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic;
  display: inline-block;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 1em 40px;
}

pre,
textarea {
  overflow: auto;
}

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

a,
b,
em,
i,
small,
strong {
  line-height: inherit;
}

dl,
ol,
p,
ul {
  line-height: 1.6;
}

body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-weight: 400;
  color: $black;
}

html {
  font-size: 100%;
  box-sizing: border-box;
}

/* *,:after,:before {
  box-sizing: inherit
} */

body {
  margin: 0;
  line-height: 1.5;
  background: $white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

select {
  width: 100%;
}

#map_canvas embed,
#map_canvas img,
#map_canvas object,
.map_canvas embed,
.map_canvas img,
.map_canvas object,
.mqa-display embed,
.mqa-display img,
.mqa-display object {
  max-width: none !important;
}

button {
  overflow: visible;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: 0 0;
  border-radius: 3px;
  line-height: 1;
}

ol,
ul {
  margin-left: 1.25rem;
}

blockquote,
dd,
div,
dl,
dt,
form,
h1,
h2,
h3,
h4,
h5,
h6,
li,
ol,
p,
pre,
td,
th,
ul {
  margin: 0;
  padding: 0;
}

dl,
ol,
p,
ul {
  margin-bottom: 1rem;
}

p {
  font-size: inherit;
  text-rendering: optimizeLegibility;
}

em,
i {
  font-style: italic;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-style: normal;
  text-rendering: optimizeLegibility;
  margin-top: 0;
  margin-bottom: 0.5rem;
  line-height: 1.4;
}

code,
kbd {
  background-color: #e6e6e6;
  color: #0a0a0a;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
  color: #cacaca;
  line-height: 0;
}

h1 {
  font-size: 1.5rem;
}

h2 {
  font-size: 1.25rem;
}

h3 {
  font-size: 1.1875rem;
}

h4 {
  font-size: 1.125rem;
}

h5 {
  font-size: 1.0625rem;
}

h6 {
  font-size: 1rem;
}

@media screen and (min-width: 40em) {
  h1 {
    font-size: 3rem;
  }

  h2 {
    font-size: 2.5rem;
  }

  h3 {
    font-size: 1.9375rem;
  }

  h4 {
    font-size: 1.5625rem;
  }

  h5 {
    font-size: 1.25rem;
  }

  h6 {
    font-size: 1rem;
  }
}

a {
  background-color: transparent;
  color: $primary;
  text-decoration: none;
  cursor: pointer;
}

a:focus,
a:hover {
  color: #00003a;
}

a img {
  border: 0;
}

hr {
  box-sizing: content-box;
  max-width: 90rem;
  height: 0;
  border-right: 0;
  border-top: 0;
  border-bottom: 1px solid #cacaca;
  border-left: 0;
  margin: 1.25rem auto;
}

dl,
ol,
ul {
  list-style-position: outside;
}

li {
  font-size: inherit;
}

ul {
  list-style-type: disc;
}

.accordion,
.menu,
.tabs {
  list-style-type: none;
}

ol ol,
ol ul,
ul ol,
ul ul {
  margin-left: 1.25rem;
  margin-bottom: 0;
}

dl dt {
  margin-bottom: 0.3rem;
  font-weight: 700;
}

.sticky.is-stuck {
  z-index: 90;
}

.bigOpacity {
  opacity: 0.2;
}

#menuarea {
  font-size: 0.95rem;
}

/*#menuarea { max-width:none; width:100%; margin-top:0; }*/
#top-menu {
  margin: 0;
}

.levels {
  display: flex;
  flex-wrap: wrap;
}

#top-menu .levels li {
  color: $primary;
  padding: 0.3rem 0.3rem 0.3rem 0.5rem;
  background-color: #ddd;
  margin: 0.3rem 0.3rem;
  border-radius: 4px;
  list-style-type: none;
  /* display: inline-block; */
}

#top-menu .levels li.selLevel {
  padding: 0.3rem 0.3rem !important;
  background-color: $primary !important;
}

#top-menu .levels li.openLevel {
  padding: 0.3rem 0.3rem !important;
  background-color: #323269 !important;
}

#top-menu .levels li.openLevel a,
#top-menu .levels li.selLevel a {
  color: #e9e9e9 !important;
}

/*#top-menu .levels li.openLevel img.otherMenuOpenIcon {
          display: none;
      }*/

#top-menu .levels a {
  padding: 0;
  position: relative;
  float: left;
}

#top-menu .levels li.selLevel a {
  padding: 0.1rem !important;
  top: 0 !important;
}

#top-menu .levels li.openLevel a {
  padding: 0.1rem !important;
  top: 0 !important;
}

/*#main-menu img { margin:0 0.4rem 0 1rem; }*/
.top-bar .levels ul {
  display: table;
  margin: 0 auto;
}

.top-bar .levels ul li {
  display: table-cell;
}

/*.top-bar.levels {
  padding: .5rem .5rem 0 .5rem;
}*/

/* .top-bar .levels a span {
  float: left;
} */

.top-bar .levels img {
  height: 1.2rem;
  margin-right: 0px;
  float: right;
  cursor: pointer;
}

.top-bar .levels a.selLevel img {
  display: none;
}

.pagetitle {
  margin-top: 1rem;
}

img.avatar {
  width: 2rem;
  border-radius: 50%;
  border: 1px solid #e9e9e9;
  margin: 0 0.5rem 0 1rem;
}

img.loginlogo {
  margin: 2rem;
}

div.loginlogo {
  background: $primary;
  width: 100%;
}

div.loginbox {
  margin: 2rem 0 0 0;
}

div.topspace {
  padding-top: 0.6rem;
}

.sedetoolbar .button {
  height: 3rem;
}

img.logo {
  float: left;
  padding-top: 2px;
  margin: 0 0.4rem 0 1rem;
}

li.avatar {
  width: 1.4rem;
}

ul.leftside {
  float: left;
}

.inheritHeight {
  height: inherit;
}

.pageMarginTop {
  margin-top: 1.8rem;
}

.marginTop_big {
  margin-top: 2rem;
}

.marginTop_medium {
  margin-top: 1rem;
}

.marginTop {
  margin-top: 0.8rem !important;
}

.marginTop_small {
  margin-top: 0.2rem;
}

.marginLeft {
  margin-left: 0.8rem !important;
}

.marginLeft_small {
  margin-left: 0.2rem !important;
}

.marginRight {
  margin-right: 0.8rem;
}

.paddingRight {
  padding-right: 0.8rem;
}

.paddingTop_small {
  padding-top: 0.2rem;
}

.marginRight-small {
  margin-right: 0.2rem;
}

.marginRight-medium {
  margin-right: 0.4rem;
}

.marginBottom {
  margin-bottom: 0.8rem;
}

.marginBottom-medium {
  margin-bottom: 0.4rem;
}

.marginBottom-small {
  margin-bottom: 0.2rem;
}

.marginBottom-big {
  margin-bottom: 30px;
}

.pageBottomMargin {
  margin-bottom: 6rem;
}

.noMarginBottom {
  margin-bottom: 0 !important;
}

.noMarginRight {
  margin-right: 0 !important;
}

.noMarginLeft {
  margin-left: 0 !important;
}

.noMargin {
  margin: 0 !important;
}

.red {
  background-color: red !important;
}

.alert {
  // background-color: $alert;
}

.lightred {
  background-color: #ffacac !important;
}

.marginTopLikeLabel {
  margin-top: 1.5rem !important;
}

.noOverflow {
  overflow: hidden;
}

.textOverflowWithDots {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.text-align-right {
  text-align: right;
}

.redBorder {
  border-color: $alert;
}

.fillWidth {
  width: 100%;
}

.fillHeight {
  height: 100%;
}

.fill {
  width: 100%;
  height: 100%;
}

.likeBlock {
  display: block;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: 0.5rem;
}

.mb-2 {
  margin-bottom: 1rem;
}

.mb-3 {
  margin-bottom: 1.5rem;
}

.mb-4 {
  margin-bottom: 2rem;
}

.mb-5 {
  margin-bottom: 3rem;
}

.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: 0.5rem;
}

.mt-2 {
  margin-top: 1rem;
}

.mt-3 {
  margin-top: 1.5rem;
}

.mt-4 {
  margin-top: 2rem;
}

.mt-5 {
  margin-top: 3rem;
}

input.ng-invalid.ng-touched,
select.ng-invalid.ng-touched,
textarea.ng-invalid.ng-touched {
  border-color: $alert-accented;
}

/*menucontainer*/
#menucontainer {
  z-index: 98 !important;
}

/*Questa servono dentro le griglie*/
.textTd input.ng-invalid,
.textTd select.ng-invalid {
  border: 2px solid $alert !important;
}

select.small {
  height: inherit;
  padding: 0.3rem;
  font-size: inherit;
}

.inheritFontSize {
  font-size: inherit;
}

.noPadding {
  padding: 0 !important;
}

.noPaddingBottom {
  padding-bottom: 0 !important;
}

.noPaddingLeft {
  padding-left: 0;
}

.noPaddingRight {
  padding-right: 0;
}

.noPaddingTop {
  padding-top: 0 !important;
}

.paddingRightLikeColumn {
  padding-right: 0.3rem;
}

.padding {
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
}

.paddingLeft {
  padding-left: 0.9375rem;
}

.paddingBottom {
  padding-bottom: 0.9375rem;
}

.paddingBottom-small {
  padding-bottom: 0.2rem;
}

.paddingTop {
  padding-top: 0.8rem;
}

.paddingTop-small {
  padding-top: 0.2rem;
}

.padding-small {
  padding: 0.2rem;
}

.alignLeft {
  text-align: left;
}

.alignRight {
  text-align: right;
}

.alignCenter {
  text-align: center;
}

.vAlignTop {
  vertical-align: top !important;
}

.smallFont {
  font-size: 0.8rem !important;
}

@-webkit-keyframes floatText {
  from {
    left: 0%;
  }

  to {
    /* left: auto; */
    left: -150px;
  }
}

.pulsing {
  animation: pulse 2s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.noborder {
  border-width: 0 !important;
}

.uppercase {
  text-transform: uppercase;
}

.showinline {
  display: inline-block;
}

.selLevel {
  background-color: $primary !important;
  color: #e9e9e9 !important;
}

.listaContGrey {
  background-color: $grey;
}

.listaContLiteGrey {
  background-color: $light-grey;
}

.selAllCk {
  margin-bottom: 5px;
}

.profileImage {
  height: 200px;
  width: 200px;
}

.sortorder:after {
  content: "\25b2";
}

.sortorder.reverse:after {
  content: "\25bc";
}

.invisible {
  display: none;
}

.noAnimation {
  animation: none !important;
}

.fieldset {
  margin-top: 0;
}

fieldset.slim {
  padding: 0;
}

fieldset.slim legend {
  font-size: 0.875rem;
  margin-left: 0.2rem;
}

select.withplaceholder option:first-child {
  display: none;
}

select option {
  font-style: normal !important;
}

input.largeFont,
button.largeFont,
a.largeFont {
  font-size: large;
}

.italicText {
  font-style: italic;
}

.noclicks {
  pointer-events: none;
}

.cursorSel {
  cursor: pointer;
}

.cursorDef {
  cursor: default;
}

.errorlbl {
  color: $alert-accented;
}

.csoftlbl {
  color: $primary;
}

.csoftbckgrnd {
  background-color: $primary;
}

.defbckgrnd {
  background-color: $light-grey;
}

.bold {
  font-weight: bold;
}

legend .button {
  margin-bottom: 0;
  height: 1.8rem;
  width: 1.8rem;
  margin-left: 1rem;
}

legend .button.letters {
  line-height: 0.1;
  width: auto !important;
}

.disabledPanel {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0.5;
  top: 0;
  left: 0;
  background-color: #ddd;
  z-index: 10;
}

.disabledPanel_transp {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0.5;
  top: 0;
  left: 0;
  z-index: 10;
}

.selectNoMargin {
  margin: 0;
  vertical-align: top;
}

/*Stili table*/

thead th {
  padding: 0;
}

thead th.textTh {
  padding-left: 0.5rem;
}

thead th.numberTh {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  text-align: center;
}

thead th.checkTh {
  text-align: center;
}

tbody td {
  padding-top: 0;
  padding-bottom: 0;
  // border: 1px solid $light-grey;
}

table.slim tbody {
  line-height: normal;
  font-size: 0.7rem;
}

.buttonTd {
  text-align: center;
  padding-left: 0;
  padding-right: 0;
  width: 4.8rem;
}

.buttonTd.multiple {
  width: auto !important;
}

.buttonTd.small {
  width: 2.8rem;
}

.buttonTd input.button,
.buttonTd button {
  margin-bottom: 0.1rem;
}

.buttonTd .buttonFill {
  margin: 0 !important;
  width: 100%;
}

.buttonTd input.iconbtn,
.buttonTd img {
  width: 2rem;
}

.buttonTd input.smallIcon {
  width: 1rem;
  height: 1rem;
}

.buttonTd input.smallBtn4Grid,
.buttonTd button.smallBtn4Grid {
  background-position: center;
  width: 1rem;
  height: 1rem;
}

.tdToBtn {
  height: 1.3rem;
  cursor: pointer;
}

.checkboxTd {
  text-align: center;
}

.checkboxTd input {
  margin-bottom: 0;
}

.textTdPadding {
  padding-left: 0.9375rem;
}

.textTd {
  padding-left: 0;
  padding-right: 0;
}

.textTd input,
.textTd select {
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  height: 1.5rem;
  border: none;
  box-shadow: none;
  background-color: inherit;
}

.numberTd {
  width: 2rem;
}

.numberTd input {
  text-align: right;
}

.colorpickerTd {
  padding-left: 0px;
  padding-right: 0px;
  width: 2rem;
}

.colorpickerTd span {
  margin-bottom: 0 !important;
}

.sliderTd {
  padding-left: 0;
  padding-right: 0;
  /*width: 5rem;
  vertical-align: middle;*/
}

.sliderTd input[type="range"] {
  width: 100%;
  vertical-align: middle;
}

table {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: initial;
}

.relative {
  position: relative;
}

.accordion {
  border-color: $grey !important;
}

.accordion-title {
  padding: 0.4rem 1rem;
  background-color: #ddd;
  border-bottom-color: #cacaca;
}

.accordion-title h5 {
  margin: 0;
}

.accordion-title div.icons {
  margin: 0 3rem 0 0;
}

.accordion-title div.icons img {
  width: 2rem;
  border-radius: 50%;
  border: 1px solid #888;
}

.accordion-title div.count {
  padding-top: 0.4rem;
  margin: 0 2%;
}

@media only screen and (max-width: 39.9999em) {
  .accordion-title div.count {
    padding-top: 0.4rem;
    margin: 0 2% 2% 2%;
  }
}

.accordion-title span.convcount {
  display: inline;
  font-size: 1rem;
  background-color: $primary;
  color: $white;
  padding: 0.4rem 0.7rem;
  border-radius: 50%;
}

.accordion-content {
  background: $white;
}

.accordion-content h5 {
  margin: 0;
}

.accordion-content.nocolor {
  background: none !important;
}

.accordion-title.noplusminus::before {
  content: none !important;
}

tr.selectedrow,
div.selectedrow {
  background-color: $primary !important;
  color: $grey !important;
}

.sedilist .dot {
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  display: inline-block;
}

.green_standard {
  background-color: $green !important;
}

.green_aggiuntiva {
  background-color: #e6f7d9 !important;
}

.green_back {
  background-color: $dark-green !important;
}

.white_standard {
  background-color: $white !important;
}

.grey_aggiuntiva {
  background-color: #f1f1ff !important;
}

.grey_terzilivellimenu {
  background-color: $light-grey !important;
}

.dark_grey {
  background-color: $grey !important;
}

.beige {
  background-color: $beige !important;
}

.dark_beige {
  background-color: #dcdcc6 !important;
}

.lighter_beige {
  background-color: #f9f9ea !important;
}

.whiteText {
  color: $white;
}

.vertical-scroll {
  overflow: hidden;
  overflow-y: auto;
}

.blueBorder {
  border: 0.5px solid $primary;
}

.oneline {
  white-space: nowrap;
}

.slimInput {
  line-height: normal !important;
  font-size: 0.7rem !important;
  margin-bottom: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  height: 1.5rem !important;
  border: none !important;
  box-shadow: none !important;
}

/*****************************/
/*********tabs****************/
.tabs-title > a.disabled {
  opacity: 0.25;
  cursor: default;
  pointer-events: none;
}

cs-tab-control li.disabled {
  opacity: 0.25;
}

.tabDisabledPanel {
  width: 100%;
  /*height: 1rem;*/
  position: absolute;
  opacity: 0.5;
  top: 0;
  left: 0;
  background-color: $light-grey;
  z-index: 10;
}

.tabDisabled {
  width: 100%;
  height: 100%;
  position: absolute;
  /*opacity: 0.5;*/
  top: 0;
  left: 0;
  background-color: transparent;
  z-index: 10;
}

span.tabStep {
  position: absolute;
  top: -0.85rem;
  right: 0rem;
  background: $alert;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: $white;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 5px;
  text-align: center;
  width: 1.6em;
}

/********TOOLTIP********/
.noFoundation.tooltip::before {
  display: none !important;
}

/***********************/
/*****************************/
/*******PAGINATION************/
.pagination li.current {
  padding: 0;
}

.pagination li.current a {
  color: $white;
}

/*****************************/
/**************span***********/
p > span,
h1 > span,
h2 > span,
h3 > span,
h4 > span,
h5 > span,
label > span {
  display: initial;
  font-size: inherit;
}

/*****************************/
/*******PAGINATION************/
.graph rect,
.graphFirst rect:first-child {
  fill: $light-grey;
}

/*******ANGULAR***************/
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
  display: none !important;
}

/*****************************/

/********Buttons**************/
.expanded .button.smallIcon {
  background-size: contain;
}

.backImgBackground {
  background-image: url("https://media.csoftonline.it/images/img/blue/back_512_blue.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.endImgBackground {
  background-image: url("https://media.csoftonline.it/images/img/blue/end_512_blue.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.fwdImgBackground {
  background-image: url("https://media.csoftonline.it/images/img/blue/right_512_blue.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.startImgBackground {
  background-image: url("https://media.csoftonline.it/images/img/blue/start_512_blue.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.arrowImgBackground {
  background-image: url("https://media.csoftonline.it/images/img/blue/arrow_512_blue.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.docImgBackground {
  background-image: url("https://media.csoftonline.it/images/img/blue/doc_512_blue.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.cancelImgBackground {
  background-image: url("https://media.csoftonline.it/images/img/blue/close_512_blue.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.cancelImgBackground.red {
  background-image: url("https://media.csoftonline.it/images/img/close_512_red_full.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.detailsImgBackground {
  background-image: url("https://media.csoftonline.it/images/img/blue/mode_512_blue.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.searchImgBackground {
  background-image: url("https://media.csoftonline.it/images/img/blue/search_512_blue.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.addImgBackground {
  background-image: url("https://media.csoftonline.it/images/img/blue/add_512_blue.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.subImgBackground {
  background-image: url("https://media.csoftonline.it/images/img/blue/minus_512_blue.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.filterImgBackground {
  background-image: url("https://media.csoftonline.it/images/img/blue/filter_512_blue.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.approveImgBackground,
.approveImgBackground:hover {
  background-color: white;
  background-image: url("https://media.csoftonline.it/images/img/approve.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.approveBluImgBackground,
.approveBluImgBackground:hover {
  background-color: white;
  background-image: url("https://media.csoftonline.it/images/img/blue/approve_512_blue.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.approveSmallBluImgBackground,
.approveSmallBluImgBackground:hover {
  background-color: white;
  background-image: url("https://media.csoftonline.it/images/img/blue/approve_blue.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.smileBadImgBackground,
.smileBadImgBackground:hover,
.smileBadImgBackground:focus {
  background: transparent;
  background-image: url("https://media.csoftonline.it/images/img/smile_bad.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  cursor: default;
}

.smileOkImgBackground,
.smileOkImgBackground:hover,
.smileOkImgBackground:focus {
  background: transparent;
  background-image: url("https://media.csoftonline.it/images/img/smile_ok.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  cursor: default;
}

.smileTooBadImgBackground,
.smileTooBadImgBackground:hover,
.smileTooBadImgBackground:focus {
  background: transparent;
  background-image: url("https://media.csoftonline.it/images/img/smile_too_bad.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  cursor: default;
}

.smileTooLowImgBackground,
.smileTooLowImgBackground:hover,
.smileTooLowImgBackground:focus {
  background: transparent;
  background-image: url("https://media.csoftonline.it/images/img/smile_too_low.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  cursor: default;
}

.smileWarningImgBackground,
.smileWarningImgBackground:hover,
.smileWarningImgBackground:focus {
  background: transparent;
  background-image: url("https://media.csoftonline.it/images/img/smile_warning.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  cursor: default;
}

.resetImgBackground {
  background-image: url("https://media.csoftonline.it/images/img/blue/reset_512_blue.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.processImgBackground {
  background-image: url("https://media.csoftonline.it/images/img/blue/process_512_blue.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.exportImgBackground {
  background-image: url("https://media.csoftonline.it/images/img/blue/down_512_blue.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.uploadImgBackground {
  background-image: url("https://media.csoftonline.it/images/img/blue/upload_512_blue.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.saveImgBackground {
  background-image: url("https://media.csoftonline.it/images/img/blue/save_512_blue.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.refreshImgBackground {
  background-image: url("https://media.csoftonline.it/images/img/blue/circular_512_blue.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.selectallImgBackground {
  background-image: url("https://media.csoftonline.it/images/img/blue/selectall_512_blue.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.deselectallImgBackground {
  background-image: url("https://media.csoftonline.it/images/img/blue/deselectall_512_blue.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.markImgBackground {
  background-image: url("https://media.csoftonline.it/images/img/blue/placeholder_512_blue.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.multimarkImgBackground {
  background-image: url("https://media.csoftonline.it/images/img/blue/multiplaceholder_512_blue.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.menuImgBackground {
  background-image: url("https://media.csoftonline.it/images/img/blue/menu_512_blue.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.binImgBackground {
  background-image: url("https://media.csoftonline.it/images/img/blue/bin_512_blue.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.pubblImgBackground {
  background-image: url("https://media.csoftonline.it/images/img/blue/thumbup_512_blue.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.copyImgBackground {
  background-image: url("https://media.csoftonline.it/images/img/blue/copy_512_blue.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.largeImgBackground {
  background-image: url("https://media.csoftonline.it/images/img/blue/double_512_blue.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.swapImgBackground {
  background-image: url("https://media.csoftonline.it/images/img/blue/swap_512_blue.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.lockImgBackground {
  background-image: url("https://media.csoftonline.it/images/img/blue/lock_512_blue.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.unlockImgBackground {
  background-image: url("https://media.csoftonline.it/images/img/blue/lock_open_512_blue.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.unlockSmallImgBackground {
  background-image: url("https://media.csoftonline.it/images/img/blue/lock_blue.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.configImgBackground {
  background-image: url("https://media.csoftonline.it/images/img/blue/configuration_512_blue.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.affilImgBackground {
  background-image: url("https://media.csoftonline.it/images/img/blue/affiliati_512_blue.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.compassImgBackground {
  background-image: url("https://media.csoftonline.it/images/img/blue/compass_512_blue.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.dollarImgBackground {
  background-image: url("https://media.csoftonline.it/images/img/blue/dollar_512_blue.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.paymentImgBackground {
  background-image: url("https://media.csoftonline.it/images/img/blue/payment_512_blue.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.truckImgBackground {
  background-image: url("https://media.csoftonline.it/images/img/blue/truck_512_blue.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.warehouseImgBackground {
  background-image: url("https://media.csoftonline.it/images/img/blue/warehouse_512_blue.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.contactsImgBackground {
  background-image: url("https://media.csoftonline.it/images/img/blue/flotta_512_blue.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.contactsImgBackground.pulsing {
  background-image: url("https://media.csoftonline.it/images/img/flotta_512_orange_full.png") !important;
  background-color: #e3923f !important;
}

.msgImgBackground {
  background-image: url("https://media.csoftonline.it/images/img/message.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

.clockImgBackground {
  background-image: url("https://media.csoftonline.it/images/img/blue/hour_512_blue.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.printerImgBackground {
  background-image: url("https://media.csoftonline.it/images/img/blue/printer_512_blue.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.pdfExportImgBackground {
  background-image: url("https://media.csoftonline.it/images/img/blue/pdf_512_blue.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.pdfExportImgBackground.transparent {
  background-image: url("https://media.csoftonline.it/images/img/blue/pdf_512_noback.png");
}

.excelExportImgBackground {
  background-image: url("https://media.csoftonline.it/images/img/blue/excel_512_blue.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.excelExportImgBackground.transparent {
  background-image: url("https://media.csoftonline.it/images/img/blue/excel_512_noback.png");
}

.wordExportImgBackground {
  background-image: url("https://media.csoftonline.it/images/img/blue/word_512_blue.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.attachmentBackground {
  background-image: url("http://media.csoftonline.it/images/img/blue/attachment_512_blue.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.editBackground {
  background-image: url("http://media.csoftonline.it/images/img/blue/edit_blue_full.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.mailImgBackground {
  background-image: url("https://media.csoftonline.it/images/img/blue/mail_512_blue_full.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.userImgBackground_small {
  background-image: url("https://media.csoftonline.it/images/img/blue/user_blue.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.calendarImgBackground {
  background-image: url("https://media.csoftonline.it/images/img/blue/calendar_512_blue.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.checkmarkImgBackground {
  background-image: url(http://localmedia.csoftonline.it/images/icons/checkmark-round.png);
  background-size: cover;
  background-repeat: no-repeat;
}

.checkmarkImgBackground.whiteCheck {
  background-image: url(http://localmedia.csoftonline.it/images/icons/checkmark-round-white.png);
}

.lockedImgBackground {
  background-image: url(http://localmedia.csoftonline.it/images/icons/locked.png);
  background-size: cover;
  background-repeat: no-repeat;
}

button.attachmentButton {
  background-image: url("http://media.csoftonline.it/images/img/blue/attachment_512_blue.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 2.5rem;
  height: 2.5rem;
  background-position: center;
}

button.closeButton {
  background-image: url("http://media.csoftonline.it/images/img/blue/close_512_blue.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 2.5rem;
  height: 2.5rem;
  background-position: center;
}

button.cancelButton {
  background-image: url("http://media.csoftonline.it/images/img/close_512_red_full.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-color: $alert;
}

.mediumBtn {
  width: 3rem;
  height: 3rem;
  background-position: center;
}

.smallBtn {
  width: 2.4rem;
  height: 2.4rem;
  background-position: center;
}

.tinyBtn {
  width: 1rem;
  height: 1rem;
  background-position: center;
}

.largeBtn {
  width: 4rem;
  height: 4rem;
  background-position: center;
}

.thumbnailBtn {
  border: 4px solid $white !important;
  box-shadow: 0 0 0 1px rgba(10, 10, 10, 0.2);
  display: inline-block;
  max-width: 100%;
  transition: box-shadow 0.2s ease-out;
  border-radius: 5px;
  margin-bottom: 1rem;
}

.close-button {
  margin-top: 10px;
  margin-right: 10px;
  position: absolute;
  color: #8a8a8a;
  border: none !important;
  right: 1rem;
  top: 0.5rem;
  font-size: 2em;
  line-height: 1;
  cursor: pointer;
  z-index: 2;
}

.close-button:hover {
  color: $primary;
}

span.badgeHelp {
  position: absolute;
  top: -0.8rem;
  right: -0.5rem;
  background: red !important;
  border-radius: 0.8em !important;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #ffffff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 5px;
  text-align: center;
  width: 1.6em;
}

span.badgeAria {
  position: absolute;
  top: 1.3rem;
  right: -0.7rem;
  background: red !important;
  border-radius: 0.8em !important;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #ffffff;
  display: inline-block;
  font-weight: bold;
  /*/line-height: 1.6em;*/
  margin-right: 5px;
  text-align: center;
  width: 1.6em;
}

.badge {
  display: inline-block;
  padding: 0.3em;
  min-width: 2.1em;
  font-size: 0.6rem;
  border-radius: 50%;
  background: #004;
  color: #fefefe;
}
.badge,
.button,
.input-group-label,
.menu.icon-top > li > a,
.orbit-bullets {
  text-align: center;
}

/*****************************/

.borderBottomRight {
  border-color: $white;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-right-style: solid;
  border-right-width: 1px;
}

/********BackGrounds**************/
.centeredBckgr {
  background-position: center;
}

.leftBckgr {
  background-position: left;
}

/*****************************/

/*******Dropdwon*************/
.cwdropdown {
  display: inline-block;
  cursor: pointer;
  -webkit-appearance: none;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  border: 1px solid transparent;
  border-radius: 3px;
  /*padding: .7em 1em;*/
  font-family: inherit;
  margin: 0 0 1rem;
  font-size: 1rem;
  background-color: $primary;
  color: $white;
  background-image: url(https://media.csoftonline.it/images/img/dropdown.png);
  background-size: 9px 6px;
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  /*line-height: 1;*/
}

.cwdropdown:disabled {
  opacity: 0.25;
  background-color: $primary;
  color: $white;
}

.cwdropdown option:checked {
  display: none;
}

/******************************/

#mapcont {
  /*width: 87.6rem;*/
  height: 40rem;
  position: relative;
  padding-left: 0;
  padding-right: 0;
}

.mediumBorder {
  border: 0.2rem solid $primary;
}

.separator {
  height: 1px;
  background-color: $primary;
  margin-bottom: 1rem;
  /*width: 15rem;*/
}

/*colorpicker*/

.simplecolorpicker.icon {
  border: 4px solid $white !important;
  box-shadow: 0 0 0 1px rgba(10, 10, 10, 0.2);
  display: inline-block;
  max-width: 100%;
  transition: box-shadow 0.2s ease-out;
  border-radius: 3px;
  margin-bottom: 1rem;
  height: 2.4rem;
  width: 2.4rem;
}

.simplecolorpicker.smallicon {
  border: 3px solid $white !important;
  height: 1.8rem;
  width: 1.8rem;
}

.simplecolorpicker.picker {
  width: 16rem;
}

/*********************/

/******offcanvas*****/
.off-canvas-content {
  background: none;
  box-shadow: none;
}

.off-canvas {
  height: 100%;
}

.off-canvas.position-right {
  right: -300px;
  width: 300px;
}

.off-canvas.position-left {
  left: -300px;
  width: 300px;
}

.carview.move-right > .inner-wrap {
  -webkit-transform: translate3d(-300, 0, 0);
  -moz-transform: translate3d(-300, 0, 0);
  -ms-transform: translate3d(-300, 0, 0);
  -o-transform: translate3d(-300, 0, 0);
  transform: translate3d(-300, 0, 0);
}

.is-open-right {
  -webkit-transform: translateX(-300px);
  -ms-transform: translateX(-300px);
  transform: translateX(-300px);
}

.is-open-left {
  -webkit-transform: translateX(300px);
  -ms-transform: translateX(300px);
  transform: translateX(300px);
}

/***********************/

input[type="number"].noSpinner {
  -moz-appearance: textfield;
}

input[type="number"].noSpinner::-webkit-inner-spin-button,
input[type="number"].noSpinner::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/*stilizzazione targa*/

.platedigit {
  max-width: 9%;
  display: inline-block;
}

.platedigitinput {
  border: none;
  height: 3.3rem;
  font-size: 2.5rem;
  padding: 0;
  font-weight: bold;
  box-shadow: none;
  transition: none;
  text-align: center;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin-top: 0.5rem;
  margin-bottom: 0;
  text-transform: uppercase;
  background-color: transparent;
  font-family: platesFont;
}

.platedigitinput:focus {
  outline: none;
  box-shadow: none;
  transition: none;
  border: none;
  background-color: transparent;
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.platedigitleft {
  display: inline-block;
  max-width: 12%;
  width: 12%;
  height: 100%;
  background: #003399;
  background-image: radial-gradient(circle at top left, #799be0, #003399 40%);
  color: white;
  line-height: 4.4rem;
  float: left;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.platedigitright {
  display: inline-block;
  max-width: 12%;
  width: 12%;
  height: 100%;
  background: #003399;
  background-image: radial-gradient(circle at left, #799be0, #003399 70%);
  color: white;
  content: " ";
  line-height: 4.4rem;
  float: right;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.platedigitparent {
  display: inline-block;
  text-align: center;
  border-style: ridge;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-image: radial-gradient(at right, #fcfcfc 25%, #f4f4f4);
}

.platedigitspace {
  display: inline-block;
  content: "";
  margin-left: 0.1rem;
  margin-right: 0.1rem;
}

.platedigitplain {
  transform: translateY(25%);
  font-weight: bolder;
  font-size: 1.8rem;
  color: transparent;
}

.platedigitplain:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 30px;
  border: 1px solid #ffcc00;
  transform: translate(-70%, -30%);
}

.platedigitsymbol {
  transform: translateY(25%);
  font-weight: bolder;
  font-size: 1.8rem;
}

.platedigitsymbol:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 30px;
  border: 1px dashed #ffcc00;
  transform: translate(-70%, -30%);
}

/*********************/

.platedigitparent_small {
  display: inline-block;
  text-align: center;
  border: 2px groove;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-image: radial-gradient(at right, #fcfcfc 25%, #f4f4f4);
  height: 1.8rem;
  width: 100%;
}

.platedigitleft_small {
  display: inline-block;
  max-width: 12%;
  width: 12%;
  height: 100%;
  background: #003399;
  background-image: radial-gradient(circle at top left, #799be0, #003399 40%);
  color: white;
  line-height: 2rem;
  float: left;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.platedigitsymbol_small {
  transform: translateY(15%);
  font-weight: bolder;
  font-size: 0.5rem;
}

.platedigitsymbol_small:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 30px;
  border: 1px dashed #ffcc00;
  transform: translate(-61%, -30%);
}

.platedigit_small {
  display: inline-block;
  /*transform: translate(0px, -3px);*/
}

.platedigitspace_small {
  display: inline-block;
  content: "";
}

.platedigitinput_small {
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  background-color: transparent;
  font-family: platesFont;
}

.platedigitright_small {
  display: inline-block;
  max-width: 12%;
  width: 12%;
  height: 100%;
  background: #003399;
  background-image: radial-gradient(circle at left, #799be0, #003399 70%);
  color: white;
  content: " ";
  line-height: 2rem;
  float: right;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.platedigitplain_small {
  transform: translateY(20%);
  color: transparent;
}

.platedigitplain_small:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 30px;
  border: 1px solid #ffcc00;
  transform: translate(-70%, -30%);
}

.thumbnail {
  border: 4px solid #fefefe;
  box-shadow: 0 0 0 1px rgba(10, 10, 10, 0.2);
  display: inline-block;
  line-height: 0;
  max-width: 100%;
  transition: box-shadow 0.2s ease-out;
  border-radius: 3px;
  margin-bottom: 1rem;
}

.thumbnail:focus,
.thumbnail:hover {
  box-shadow: 0 0 6px 1px rgba(0, 0, 68, 0.5);
}

input[type="number"].noSpinner::-webkit-inner-spin-button,
input[type="number"].noSpinner::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

button.saveButton {
  background-image: url("https://media.csoftonline.it/images/img/blue/save_512_blue.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 2.5rem;
  height: 2.5rem;
  background-position: center;
}

mat-toolbar-row {
  white-space: normal !important;
}

/*********icone anomalie**********/
.icon {
  height: 2.5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

//Media queries
@media screen and (min-width: 30em) and (max-width: 59.9999em) {
  .smartp-hide,
  .desktop-show,
  .tablet-show {
    display: none !important;
    height: 0;
  }
}

@media screen and (min-width: 90em) {
  .desktop-hide,
  .tablet-show,
  .smartp-show {
    display: none !important;
  }
}

/* landscape tablet and normal monitor (>= 960px < 1440px) */
@media only screen and (min-width: 60em) and (max-width: 89.9999em) {
  .tablet-hide,
  .desktop-show,
  .smartp-show {
    display: none !important;
  }
}

/* portrait phone (< 480px) */
@media screen and (max-width: 29.9999em) {
  .smartp-hide,
  .desktop-show,
  .tablet-show {
    display: none !important;
  }
}

/*Only phone hide*/
@media screen and (min-width: 0px) and (max-width: 740px) {
  .smarp-hide-only {
    display: none !important;
  }
}

/*Only phone show*/
@media screen and (min-width: 741px) {
  .smarp-show-only {
    display: none !important;
  }
}

@media screen and (min-width: 0em) and (max-width: 39.9375em) {
  .hide-for-small-only {
    display: none !important;
  }
}

@media screen and (max-width: 0em), screen and (min-width: 40em) {
  .show-for-small-only {
    display: none !important;
  }
}

@media screen and (min-width: 40em) {
  .hide-for-medium {
    display: none !important;
  }
}

@media screen and (max-width: 39.9375em) {
  .show-for-medium {
    display: none !important;
  }
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .hide-for-medium-only {
    display: none !important;
  }
}

@media screen and (max-width: 39.9375em), screen and (min-width: 64em) {
  .show-for-medium-only {
    display: none !important;
  }
}

@media screen and (min-width: 64em) {
  .hide-for-large {
    display: none !important;
  }
}

@media screen and (max-width: 63.9375em) {
  .show-for-large {
    display: none !important;
  }
}

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .hide-for-large-only {
    display: none !important;
  }
}

@media screen and (max-width: 63.9375em), screen and (min-width: 75em) {
  .show-for-large-only {
    display: none !important;
  }
}
