@media (min-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1440px !important;
  }
}

.d-flex {
  flex-wrap: wrap;
}
