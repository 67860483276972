table {
  margin-top: 0;
  margin-bottom: 0.2rem;
}

thead {
  background-color: $grey;
  color: $primary;
}

table {
  border-spacing: 0;
  margin-bottom: 1rem;
  border-radius: 5px solid inherit;
}

thead {
  background-color: #ddd;
  color: #004;
}

tbody tr {
  background-color: #fefefe;
}

tbody td {
  padding-top: 0;
  padding-bottom: 0;
  border: 1px solid #d9d9d9;
}

tbody tr:nth-child(even) {
  background-color: rgb(238, 238, 255);
}

tr th,
tr td {
  text-align: start;
  // padding-inline: 0.6rem;
}

.scrollable {
  margin: 0;
  padding: 0;
  max-height: 50vh;
  overflow-y: scroll;
}

.scrollable table {
  margin-bottom: 0;
}

table thead {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0 !important;
  z-index: 1 !important;
}

.loading-border {
  display: inline-block;
  // border-bottom: 0.15rem solid blue; /* Initial border settings */
  width: 100%;
  transition: border-bottom 1s; /* Smooth transition for hover */
  animation: borderLoadingAnimation 1s ease-in-out infinite; /* Start animation */
}

@keyframes borderLoadingAnimation {
  0%,
  100% {
    border-bottom: 0; /* Start and end with no border */
  }
  50% {
    border-bottom: 0.15rem solid $primary; /* Full border width in the middle of animation */
  }
}
