select {
  height: 2.4375rem;
  width: 100%;
  padding: 0.5rem;
  margin: 0 0 1rem;
  font-size: 1rem;
  cursor: pointer;
  font-family: inherit;
  line-height: normal;
  color: #0a0a0a;
  background-color: $white;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="32" height="24" viewBox="0 0 32 24"><polygon points="0,0 32,0 16,24" style="fill: rgb%2851, 51, 51%29"></polygon></svg>');
  border-radius: 5px;
  border: 1px solid #cacaca;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-size: 9px 6px;
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
}

option {
  padding: 10rem !important;
}

select:disabled,
select[disabled] {
  cursor: not-allowed;
  // filter: opacity(30%);
  background-color: $secondary;
}
