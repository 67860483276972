[type="text"],
[type="password"],
[type="date"],
[type="datetime"],
[type="datetime-local"],
[type="month"],
[type="week"],
[type="email"],
[type="number"],
[type="search"],
[type="tel"],
[type="time"],
[type="url"],
[type="color"],
[type="file"],
textarea {
  box-sizing: border-box;
  height: 2.4rem;
  width: 100%;
  padding: 0.5rem;
  border: 1px solid $border-button;
  margin: 0 0 1rem;
  font-family: inherit;
  font-size: 1rem;
  color: $black;
  background-color: $white;
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
  border-radius: 5px;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
}

input {
  line-height: normal;
}

input[type="file"]{
  display: none;
}

label.button:hover {
  background-color: rgb(87, 132, 237) !important;
  cursor: pointer;
}

[type="text"]:focus,
[type="password"]:focus,
[type="date"]:focus,
[type="datetime"]:focus,
[type="datetime-local"]:focus,
[type="month"]:focus,
[type="week"]:focus,
[type="email"]:focus,
[type="number"]:focus,
[type="search"]:focus,
[type="tel"]:focus,
[type="time"]:focus,
[type="url"]:focus,
[type="color"]:focus,
[type="file"]:focus,
textarea:focus {
  border: 1px solid #8a8a8a;
  background-color: #fefefe;
  outline: 0;
  box-shadow: 0 0 5px #cacaca;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
}

input .disabled {
  cursor: not-allowed !important;
  // filter: opacity(30%) !important;
  background-color: $secondary !important;
}

input[type="button"] .disabled,
input[type="button"]:disabled {
  filter: opacity(30%);
  background-color: $primary;
}

input[type="submit"] {
  cursor: pointer;
}